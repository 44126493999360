@import url('https://fonts.googleapis.com/css2?family=Castoro+Titling&family=Raleway:wght@300&display=swap');

body {
  font-family: 'Raleway', sans-serif;;
  font-weight: lighter;
  background-color: #282c34;
  color: whitesmoke;
}

h3 {
  font-weight: normal;
}
.App {
  text-align: center;
}

.HomeBox {
  height: 60%;
  width: 60%;
  justify-content: center;
  align-items: center;
  margin-top: 5%;
  margin-left: 20%;
  display: flex;
  flex-direction: row;
  animation: intro-load 2000ms ease-in;
  animation-delay: 500ms;
  padding: 3.5% 0 2% 0;
}

.ContactBox {
  font-size: 1.3vw; 
  font-weight: lighter;
  height: 70%;
  width: 70%;
  padding: 15% 0 0 0;
  margin-left: 15%;
  margin-bottom: 5%;
  justify-content: center;
  align-content: center;
  display: flex;
  flex-direction: column;
}

.ExperienceBox {
  height: 70%;
  width: 70%;
  margin-left: 15%;
  padding-bottom: 2%;
  justify-content: center;
  align-content: center;
  display: flex;
  flex-direction: column;
}

.ProjectsBox {
  margin-top: -2%;
  margin-bottom: 3%;
  justify-content: center;
  align-content: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.ProjectsBox > a {
  flex: 30%;
  padding: 2%;
  margin: 2%;
  border: 3px solid whitesmoke;
  border-radius: 5%;
}

.ProjectBox {
  width: 40%;
  height: 40%;
}

.ProjectBox:hover {
  box-shadow: inset 0 0 0 10em rgba(255, 255, 255, 0.3);
}

.ProjectInfo {
  font-size: 1.3vw;
  justify-content: center;
}

.LinksBox {
  display: flex; 
  flex-direction: row;
  justify-content: center;
}

.JobBox {
  display: flex;
  flex-direction: row;
  padding-bottom: 5%;
  height: 30%;
  white-space: pre-wrap;
}

#projects h1 {
  text-decoration: underline;
}

#experience > h1 {
  text-decoration: underline;
}

i {
  font-size: 5vw;
}

.JobBox img {
  height: 30%;
  width: 35%;
  margin-right: 5%;
  margin-top: 3%;
}

.JobInfo {
  display: flex;
  flex-direction: column;
}

.IntroBox {
  width: 30%;
  margin-top: 8%;
  margin-right: 2%;
}

.Intro {
  font-family: 'Castoro Titling', cursive;
  font-size: 3vw;
  font-weight: bold;
}

.homePhoto {
  height: 35%;
  width: 50%;
}

.links {
  margin: 1%;
}

nav {
  background-color: whitesmoke;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
}

nav ul {
  margin: 0px;
  display: flex;
  justify-content: right;
}

nav li {
  list-style: none;
  padding: 0.7vw;
  margin: 0 1vw;
  color: #282c34;
};

nav a {
  text-decoration: none;
  color: inherit;
}

a { 
  font-size: 1.2vw;
  text-decoration: none;
  color: inherit;
}

/* Mobile Specific */
@media screen and (max-width: 600px) {


  .Intro {
    font-size: 7vw;
  }

  .IntroBox {
    width: 70%;
  }

  .homePhoto{
    width: 70%;
    height: 50%;
    size: 5vw
  }

  .HomeBox {
    flex-direction: column;
    margin-top: 15%;
  }
  

  .JobBox {
    flex-direction: column;
  }

  nav li {
    padding: 4vw;
    margin-left: 1.75vw;
    margin-right: 1.75vw;
  }

  a{
    font-size: 3.5vw;
  }
  
  .ProjectInfo {
    font-size: 2.8vw;
  }

  .ProjectBox {
    width: 80%;
    height: 50%;
  }

  .ProjectsBox {
    flex-direction: column;
  }

  .JobBox img {
    margin-left: 25%;
    width: 50%;
  }

  .JobInfo h1 {
    font-size: 5vw;
  }

  .JobInfo h3{
    font-size: 4vw;
  }

  .JobInfo p {
    font-size: 3.5vw;
  }

  .ContactBox h1 {
    font-size: 4.5vw;
  }

}

/* Animations */

@keyframes intro-load {
  0% {
    opacity: 0%;
  }
  100% {
    opacity: 100%;
  }
}

html {
  scroll-behavior: smooth;
}

#projects {
  padding: 5%;
}

#experience {
  padding: 5%;
}